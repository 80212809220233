
#itt-sl-ucl, #root, #sl-app {
  .toggle-card-add-00 {

    cursor: pointer;
  }

  .prices_detail {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-content: flex-start;
    align-items: flex-start;
  }

  .crossed_price_detail {
    position: relative;
    font-size: 1.50em;
    line-height: 1.4;
  }

  .crossed_price_detail::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    opacity: 0.5;
    background-color: var(--bg-dark);
  }

  .block div > .prices_detail > span {
    flex: 1 1 320px;
  }
}