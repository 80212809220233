#itt-sl-ucl, #root, #sl-app {

  .main {
    flex: 1 1 50.00%;
    max-height: 1000.0em;
  }

  .categoryCount {
    font-family: 'ucl-custom-bold', 'sofo-bold', Courier, monospace;
  }

  .head .pagina {
    opacity: 1.00;
    height: 1.5em;
    margin-right: -1.5em;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
    font-size: 1.15em;

    span {
      font-family: 'sofo-norm', Courier, monospace;
      font-size: 1em;
      white-space: pre-wrap;
    }

    > a {
      display: block;
      height: 100.0%;
      aspect-ratio: 1 / 1;
      margin: 0 1em;
      background-image: url(../assets/images/arrow_right.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 2.5em;


    }

    > a:first-child {
      transform: rotate(180deg);
    }

    a.disable {
      opacity: 0.1;
      cursor: default;
    }
  }
  footer {
    display: none;
  }
}