@import "../assets/styling/css/index.scss";
@import "../assets/styling/css/main.scss";
@import "../assets/styling/css/swiper.scss";
@import "../assets/styling/css/mobil.scss";

#itt-sl-ucl, #root, #sl-app {

  --containerSize: 100cqw;

  #sl-content-root {
    font-size: calc(var(--ucl-font-factor, 1) * (12px + (18 - 10) * ((var(--containerSize) - 375px) / (3840 - 375))));
  }

  .img {
    height: unset;
  }
  button {
    width: unset;
  }

  button {
    cursor: pointer;
    white-space: unset;
  }

  footer, header {

    &:after {
      background: none;
    }
  }

  .swiper-tip {
    height: auto;

    .swiper-wrapper {
      align-items: flex-start;
    }
  }
  .swiper-wrapper {
    overflow: initial;
  }

  .overlay {
    position: fixed !important;
  }

  .head .pagina {
    opacity: 1.00;
    height: 1.5em;
    margin-right: -1.5em;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
    font-family: 'sofo-bold', Courier, monospace;

    span {
      font-family: 'sofo-norm', Courier, monospace;
      font-size: inherit;
      white-space: pre-wrap;
    }

    div {
      margin-right: 3em;
    }

    > a {
      display: block;
      height: 100.0%;
      aspect-ratio: 1 / 1;
      margin: 0 1em;
      background-image: url(../assets/images/arrow_right.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 2.5em;


    }

    > a:first-child {
      transform: rotate(180deg);
    }

    a.disable {
      opacity: 0.1;
      cursor: default;
    }
  }

  .prices {
    margin-top: 0em;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-content: center;
    align-items: center;
  }

  .crossed_price {
    color: var(--txt-light);
    position: relative;
  }
  .crossed_price::after {
    position: absolute;
    content: "";
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    opacity: 0.375;
    border-color: var(--border);
    background-color: var(--bg-dark);
  }
}