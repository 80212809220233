#itt-sl-ucl, #root, #sl-app {
  .cartItem {
    display: flex;
    border-bottom-width: 1px;

    .delete {
      position: relative;
      width: 20%;
      display: inline-block;
      flex-grow:0;
      padding: 0.75em 0em 0.75em 0em;
      align-content: center;
      align-items: center;
    }

    .delete-dummy {
      position: relative;
      width: 20%;
      display: inline-block;
      flex-grow:0;
      padding: 0.75em 0em 0.75em 0em;
      align-content: center;
      align-items: center;
    }

    .unit-price-cart {
      font-size: 12px;
    }
  }

}