.sl-browserNotSupported {

  position: relative;
  margin-top: 20px;
  padding: 20px;
  width: 100%;
  left: 50%;
  transform: translate(-50%, 0%);
  font-family: Arial, "Sans-Serif", serif, courier;
  text-align: center;

  .sl-notSupportedHeadline {
    font-size: 20px;

  }

  .sl-notSupportedText {
    margin-top: 10px;
    font-size: 14px;
  }

}
