﻿#itt-sl-ucl, #root, #sl-app {

	.outOfOrder .errorMessageBreak {
		display: inherit;
	}

@media (max-width: 1081px) 		{

		.swiper-button-next, 
		.swiper-button-prev 	{	display: none;  }
	
		.menu, .card 			{	max-height: calc(100.0vh - 4.00em - 3.00em); }

}

@media (max-width: 750px) 		{

		.detail	.contain		{	display: block; padding-bottom: 1.50em; }
	
		.menu, .card			{	position: absolute; top: 0; left: 0; flex: 0 0 0; max-width: 0; height: 100.0%; }
.menu-on .menu, .card-on .card	{	min-width: 100.0%; }
			
	.menu-on.card-on .menu		{	flex: 0 0 0; max-width: 0;	 }

	.claim 						{	display: none; }

	.outOfOrder .errorMessageBreak {
		display: none;
	}
 }

/*	=================================
		END
	================================= */

}