#itt-sl-ucl, #root, #sl-app {


  .list-claim {
    span {
      span {
        font-size: 1.50em;
        line-height: 1.4;
        animation: blop infinite ease-in-out 6s;
        transform: scale(1.0);
        width: 100.0%;
        aspect-ratio: 1 / 1;
        padding: 2.00em;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-content: center;
        align-items: center;
        border-color: var(--border);
      }
    }
  }

}