
#itt-sl-ucl, #root, #sl-app {

  .main {
    flex: 0 0 0;
    max-height: 0;
  }

  .detailsContacts .contact-details p {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.4;
  }

  .contact-details {
    margin-top: 15px !important;
  }

  .trademark {
    margin-top: 0.5em !important;
  }

  .detail {
    flex: 1 1 50.00%;
    max-height: 1000.0em;

    .carline > span {
      font-size: 0.90em;
      font-family: 'ucl-custom-norm', 'sofo-norm', Courier, monospace;
      font-weight: normal;
      font-style: normal;
    }

    .description {
      font-family: 'ucl-custom-norm', 'sofo-norm', Courier, monospace;
      h1, h2, h3, h4, h5, h6 {
        text-align: left;
        margin: 10px 0;
        font-size: 1.6em;
      }

      h1 {
        font-size: 1.6em;
        font-weight: 800;
      }

      h2 {
        font-size: 1.4em;
        font-weight: 700;
      }

      h3 {
        font-size: 1.2em;
        font-weight: 600;
      }

      h4 {
        font-size: 1.1em;
        font-weight: 500;
      }

      h5 {
        font-size: 1em;
        font-weight: 500;
      }

      h6 {
        font-size: 0.9em;
        font-weight: 400;
      }

      p {
        text-align: left;
        margin-top: 5px;
        margin-bottom: 5px;
        line-height: 150%;
      }

      ul, ol {
        margin: 24px;
        margin-top: 0;
        padding-left: 20px;
        list-style-position: outside;
        line-height: 25px;
      }

      ul {
        list-style-type: disc;
      }

      ol li {
        list-style-type: decimal;
      }

      b, strong {
        font-weight: 600;
      }

      i, em {
        font-style: italic;
        color: inherit;
      }

      small {
        font-size: 0.8em;
        line-height: 1.2;
      }

      sub {
        font-size: 0.85em;
        line-height: 1;
        vertical-align: baseline;
      }

      a {
        cursor: pointer;
        text-decoration: underline;

        &:hover {
          color: rgba(0, 0, 0, 0.7);
        }
      }

      hr {
        border: none;
        height: 1px;
        background-color: rgba(128, 128, 128, 0.5);
        margin: 20px 0;
      }

      table {
        margin-top: 20px;
        border-collapse: collapse;
        overflow: auto;
        width: 100%;
      }

      tr {
        border-bottom: 1px solid rgba(128, 128, 128, 0.5);
        line-height: 20px;
      }

      td {
        padding: 10px;
        max-width: 256px;
      }

      th, td h1 {
        font-size: 16px;
        font-weight: 600;
        border: none;
        padding: 16px;
        text-align: left;
      }
    }
  }

  .detail .contain {
    grid-template-columns: var(--ucl-product-detail-grid, 38.0%) auto;

    .zoom {
      position: absolute;
      z-index: 99;
      bottom: 0;
      left: 0;
      width: 100.0%;
      height: 100.0%;
      transform: scale(1);
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-content: flex-start;
      align-items: flex-start;
      box-shadow: inset 0 0 0 10000em rgba(255, 255, 255, 0.80 );

      .iconclose {
        position: absolute;
        z-index: 100003;
        top: 0;
        right: 0;
        width: 3em;
        aspect-ratio: 1 / 1;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 2em auto;
        background-image: url(../assets/images/picts/close_white.svg);
        background-color: black;
      }
    }
  }

  .swiper-button-disabled {
    visibility: hidden;
    opacity: 0;
    transition: 0.5s ease-in-out;
  }

  .gal-hidden {
    display: none;
  }

  .video-slide {
    width: 100%;
    height: 100%;
    max-width: 100%;
    display: block;
    margin: 0;
    padding: 0;
    object-fit: cover;
    position: relative;
    z-index: 10;
  }

  .swiper-pagination-gal .swiper-pagination-bullet {
    height: auto;
    background-repeat: no-repeat;
    background-position: center;
  }

  .swiper-gal .img .iconzoom {
    position: absolute;
    z-index: 3;
    top: 0;
    right: 0;
    width: 3em;
    aspect-ratio: 1 / 1;
  }

  .swiper-gal .img .iconzoom:before {
    background-color: black;
    opacity: 0.50;
  }

  .swiper-gal .img .iconzoom:before, .gallery .swiper-gal .img .iconzoom:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100.0%;
    aspect-ratio: 1 / 1;

  }

  .swiper-gal .img .iconzoom:after {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 2em auto;
    background-image: url(../assets/images/picts/search_white.svg);
  }

  .swiper-gal .img .iconzoom:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100.0%;
    aspect-ratio: 1 / 1;
  }

  @media(max-width: 1080px){
    .swiper-gal .img .iconzoom, .detail .contain .zoom {
      display: none;
    }
  }
}
