﻿#itt-sl-ucl, #root, #sl-app {

  html, body {
    width: 100.0%;
  }

  body {
    overflow-y: scroll;
  }

  *, *:after, *:before {
    margin: 0;
    padding: 0;
    border: 0;
    border-bottom-style: solid;
    box-sizing: border-box;
  }

  img, ul {
    display: block;
  }

  .scroll {
    overflow: auto;
    scrollbar-width: none; /*box-shadow: inset 0 0 0 1.00em pink; */
  }

  .scroll::-webkit-scrollbar {
    display: none;
  }

  .scroll > label {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    span:nth-child(1) {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  /* ---- Teaser --- */

  #teaser {
    width: 100.0%;
    overflow: hidden;
  }

  .tip {
    position: relative;
    width: 100.0%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-content: stretch;
    align-items: stretch;
    overflow: hidden;
  }

  .tip > .claim {
    flex: 1 1 20.00%;
    position: relative;
    min-width: 10.00em;
    min-height: 7.50em;
  }

  .tip > .claim > span {
    position: absolute;
    top: 0;
    width: 100.0%;
    height: 100.00%;
    padding: 2.00em var(--marginfix);
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
  }

  .tip > .swiper {
    flex: 1 1 80.00%;
  }

  .cat > .swiper {
    position: relative;
    width: 100.0%;
  }

  .start {
    position: relative;
    width: 100.0%;
    margin-top: 2.00em;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-content: flex-end;
    align-items: flex-end;
    border-top-width: 1px;
    border-top-style: solid;
  }

  .start > span {
    margin-top: 2.00em;
    padding: 0 var(--marginfix);
  }

  .start > .txt {
    flex: 1 1 50.00%;
    position: relative;
    max-width: 90.00em;
    padding-left: calc(4.00em + var(--marginfix));
    padding-bottom: 1.00em;
  }

  .start > .txt > svg {
    position: absolute;
    top: 0;
    left: var(--marginfix);
    width: 3.00em;
    height: auto;
  }

  .start > .txt + span {
    flex: 1 1 30.00%;
    min-width: 25.00em;
    display: flex;
    justify-content: flex-end;
    flex-flow: row wrap;
  }

  /*.start > .txt			{	filter: invert(22%) sepia(100%) saturate(4740%) hue-rotate(292deg) brightness(111%) contrast(127%); }*/

  /* ---- Shop --- */
  #category.swiper {
    overflow: unset;
  }

  #category .swiper {
    overflow: unset;
  }

  #shop {
    width: 100.0%;
    overflow: hidden;
  }

  .content {
    position: relative;
    width: 100.0%;
    min-height: 55.00em;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-content: stretch;
    align-items: stretch;
    overflow: hidden;
  }

  /* Nav */

  nav {
    position: relative;
    top: 0;
    z-index: 2;
    width: 100.0%;
    height: 3.00em;
    padding: 0 var(--marginfix);
    border-bottom-width: 1px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-content: stretch;
    align-items: stretch;
  }

  nav > span {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-content: center;
    align-items: center;
  }

  nav > span > a {
    position: relative;
    width: 2.50em;
    aspect-ratio: 1 / 1;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1.75em;
  }

  nav > span > a:before {
    padding-left: 2.50em;
    white-space: nowrap;
  }

  nav a.filter {
    background-image: url('../img/picts/tune.svg');
  }

  nav a.glass {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* Menu */

  .menu {
    position: relative;
    z-index: 4;
    border-right-width: 1px;
    margin-left: -1px;
    overflow: hidden;
  }

  .menu .scroll {
    position: absolute;
    top: 2.50em;
    left: 0;
    width: 100.0%;
    height: calc(100.0% - 2.50em - 5.00em);
    padding: 0 2.00em;
  }

  /* Search */

  .search {
    position: absolute;
    z-index: 5;
    top: 0;
    right: -1px;
    height: calc(6.00em - 1px);
    border-left-width: 1px;
    overflow: hidden;
  }

  .search .scroll {
    position: absolute;
    top: 2.50em;
    left: 0;
    width: 100.0%;
    height: calc(100.0% - 2.50em - 5.00em); /* box-shadow: inset 0 0 0 1.00em pink; */
  }

  .searchbox {
    position: relative;
    width: 100.0%;
    height: calc(3.50em - 1px);
  }

  .searchbox > * {
    position: absolute;
    top: 0;
    height: 100.0%;
  }

  .searchbox > input {
    position: absolute;
    left: 0;
    width: calc(100.0% - 3em);
    padding: 0 1.00em;
    border-bottom-width: 1px;
  }

  .searchbox > input + a {
    position: absolute;
    right: 0;
    width: 3.50em;
    z-index: 2;
    background-image: url('../img/picts/search_white.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 2.00em;
  }

  .search .scroll {
    height: auto;
  }

  .search .megasearch {
    position: relative;
    width: 100.0%;
    max-height: 0;
    padding: 0 2.00em;
    margin-top: 2.00em;
    overflow: hidden;
  }

  .search .action {
    display: none;
    opacity: 0.00;
  }

  /* Card */

  .card {
    position: relative;
    z-index: 5;
    border-left-width: 1px;
    margin-right: -1px;
    overflow: hidden;
  }

  .card .scroll {
    position: absolute;
    top: 2.50em;
    left: 0;
    width: 100.0%;
    height: calc(100.0% - 2.50em - 9.00em);
  }

  .card .item {
    position: relative;
    width: 80%;
    padding: 0.75em 0 0.75em 2.00em;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
  }

  .card .item > span {
    flex: 1 1 auto;
    margin: 0.25em 0;
  }

  .card .item > span + span {
    flex: 0 0 auto;
    margin: 0.25em 0;
  }

  .card .delete:before {
    opacity: 0.25;
    background-image: url('../img/picts/cancel_outline.svg');
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 3.50em;
    height: 100.0%;
    background-repeat: no-repeat;
    background-position: center left 1.00em;
    background-size: 50.00%;
    background-size: 1.50em;
  }

  .card .delete:hover:before {
    opacity: 1.00;
    background-image: url('../img/picts/cancel_white.svg');
  }

  .card .delete:after {
    background-image: url('../img/picts/cancel_white.svg');
    content: '';
    position: absolute;
    top: 0;
    right: -3.50em;
    width: 3.50em;
    height: 100.0%;
    background-repeat: no-repeat;
    background-position: center left 1.00em;
    background-size: 50.00%;
    background-size: 1.50em;
  }

  .card .delete:hover:after {
    right: 0;
  }

  .card #item-00:before,
  .card #item-00:after {
    display: none;
  }

  .card #end {
    position: absolute;
    top: 0;
    left: 0;
    width: 100.0%;
    height: 100.0%;
    padding: 0 var(--marginfix);
    display: none;
    flex-flow: row wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  /* Cat Labels */

  label {
    position: relative;
    height: 3.00em;
    padding-left: 3.00em;
    border-bottom-width: 1px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-content: center;
    align-items: center;
  }

  label:last-child {
    border-bottom-width: 0;
  }

  input + label:before,
  input + label:after {
    content: '';
    position: absolute;
    aspect-ratio: 1 / 1;
  }

  input + label:before {
    top: 0.50em;
    left: 0;
    width: 2.00em;
    border-width: 2px;
  }

  input + label:after {
    top: 1.00em;
    left: 0.50em;
    width: 1.00em;
  }

  input[type=radio] + label:before,
  input[type=radio] + label:after {
    border-radius: 100.0%;
  }

  input + label + div {
    max-height: 0;
    padding-left: 3.00em;
    overflow: hidden;
  }

  input:checked + label + div {
    max-height: 20.00em;
  }

  /* Action */

  .action {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100.0%;
    height: 7.00em;
    padding: 0 var(--marginfix);
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .menu .action {
    height: 5.00em;
    border-top-width: 1px;
  }

  .menu .search {
    height: 5.00em;
    border-top-width: 1px;
  }

  .card .action {
    height: 9.00em;
    border-top-width: 2px;
  }

  .card .action > span {
    display: inline-block;
    width: 100.0%;
    margin-bottom: 1.00em;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-content: flex-end;
    align-items: flex-end;
  }

  .card .action > span > span {
    flex: 0 0 auto;
  }

  /* Main + Detail */

  .main, .detail {
    overflow: hidden;
  }

  .detail .contain {
    width: 100.0%;
    overflow: hidden;
    display: grid;
    gap: 2.00em;
  }

  /* Gallery */
  .gallery {
    padding: 0 2.00em;
  }

  .gallery > .swiper {
    width: 100.0%;
  }

  .gallery .swiper-gal {
    position: relative;
    aspect-ratio: 1 / 1;
    overflow: hidden !important;
    margin: 1.25rem 0px 0;
  }

  .gallery .swiper-gal .img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100.0%;
    height: 100.0%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .gallery .swiper-slide > .img {
    padding: 0 var(--marginfix);
  }

  /* Descript */

  .descript .block {
    padding: 2.00em;
  }

  .descript .block div + * {
    margin-top: 1.50em;
  }

  .descript .block div > .price {
    margin-right: 1.00em;
  }

  details {
    margin-top: 2.00em;
    border-width: 1px 1px 0;
    border-style: solid;
  }

  details + details {
    margin-top: 0;
    border-bottom-width: 1px;
  }

  details summary {
    position: relative;
    height: 3.00em;
    padding-left: 3.00em;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
  }

  details summary:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100.0%;
    width: 3.00em;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50.00%;
    background-image: url('../img/picts/add.svg');
  }

  details[open] summary:before {
    transform: rotate(-45deg);
  }

  details > div {
    max-height: 0;
    padding: 0 1.00em;
    overflow: hidden;
  }

  details[open] > div {
    max-height: 100.0em;
    padding-bottom: 1.00em;
  }

  .carline {
    columns: auto 15.00em;
    column-gap: 1.00em;
  }

  .carline span {
    display: block;
  }

  .carline span + span {
    margin-top: 0.50em;
  }

  /* --- Kacheln --- */

  .pad {
    position: relative;
    display: block;
    width: 100.0%;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    align-content: stretch;
    align-items: stretch;
    overflow: hidden;
  }

  .pad > span {
    position: relative;
    display: block;
    width: 100.0%;
  }

  .pad > span > span {
    position: relative;
    width: calc(100.0% - 2.00em);
    margin-left: 1.00em;
    margin-right: 1.00em;
  }

  .pad .img {
    display: block;
    width: 100.0%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .pad .title {
    margin-top: 1.00em;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  /* Pro */

  .pro {
    padding-bottom: 2.00em;
  }

  .pro .title {
    -webkit-line-clamp: 2;
  }

  .pro .links {
    margin-top: 0.50em;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-content: center;
    align-items: center;
  }

  .pro.top:after {
    content: 'TOP';
    position: absolute;
    top: 0.50em;
    left: 0.50em;
    padding: 0.25em 0.50em;
  }

  .pro .add {
    height: 2.00em;
    width: auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
  }

  .pro-00:before {
    width: 100.0%;
    aspect-ratio: 1 / 1;
    padding: 2.00em;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
  }


  .pro.pro-01 .img:before, .pro.pro-01 .img:after,
  .pro.pro-02 .img:before, .pro.pro-02 .img:after,
  .pro.pro-03 .img:before, .pro.pro-03 .img:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100.0%;
    height: 100.0%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    transform: scale(0);
  }

  .pro.pro-01 .img:before,
  .pro.pro-02 .img:before,
  .pro.pro-03 .img:before {
    content: '';
    opacity: 0.50;
  }

  .pro .img {
    position: relative;
  }

  .pro.pro-01 .img:after,
  .pro.pro-02 .img:after,
  .pro.pro-03 .img:after {
    content: 'Es wurde 1 Artikel, zu dem von Ihnen gewählten Fahrzeug, hinzugefügt.';
    padding: 1.00em;
  }

  /* Cat */

  .cat .title {
    width: calc(100.0% - var(--marginfix));
    margin: 0.50em 0 0 var(--marginfix);
    -webkit-line-clamp: 1;
  }

  .cat .title:before {
    display: block;
    width: calc(100.0% - 3.00em);
  }

  .cat .title:after {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: scale(0.80);
    transform-origin: right bottom;
    opacity: 0.50;
  }

  /* --- Header + Footer --- */

  header, footer {
    position: relative;
    width: 100.0%;
    height: 4.00em;
    padding: 1.00em var(--marginfix);
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-content: center;
    align-items: center;
  }

  header {
    border-bottom-width: 1px;
  }

  footer {
    height: auto;
    border-top-width: 1px;
  }

  header span, footer span {
    display: block;
    width: 100.0%;
  }

  header span, footer span + span {
    margin-top: 0.50em;
  }

  /* --- Elemente --- */

  .head {
    position: relative;
    width: 100.0%;
    min-width: 15.00em;
    padding: 1.00em 2.00em;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-content: flex-end;
    align-items: flex-end;
  }

  .head span + span {
    transform: scale(0.70);
    transform-origin: right bottom;
    opacity: 0.50;
  }

  .swiper {
    position: relative;
    width: 100.0%;
    overflow: hidden;
  }

  .btn {
    position: static;
    width: 100.0%;
    height: 3.00em;
    max-width: 25.00em;
    padding: 0 2.00em;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .control {
    width: 100.0%;
    min-width: 15.00em;
    height: 2.50em;
    padding: 0 2.00em;
    border-bottom-width: 1px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-content: stretch;
    align-items: stretch;
  }

  .control > span {
    justify-content: flex-start;
    flex: 1 1 30.00%;
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    align-items: center;
  }

  .control > span + span {
    justify-content: flex-end;
  }

  .overlay {
    position: absolute;
    z-index: 999;
    bottom: 0;
    left: 0;
    width: 100.0%;
    height: 100.0%;
    transform: scale(0);
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .overlay > div {
    max-width: 20.00em;
    padding: 1.50em 3.00em 3.00em 3.00em;
    border-width: 1px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    box-shadow: 0 0 0 1000.0em rgba(255, 255, 255, 0.80);
  }

  .overlay > div:before {
    content: '';
    display: block;
    width: 4.00em;
    aspect-ratio: 1 / 1;
    margin-bottom: 1.00em;
    opacity: 0.25;
    transform: rotate(-90deg);
    background-image: url(../img/picts/forward.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  /*	=================================
          Actions
      ================================= */

  .cartItem .delete:before {
    transition: all 150ms ease-in-out 0ms;
  }

  .menu, .card, .search, .detail,
  .megasearch, .overlay,
  .cartItem .delete:after {
    transition: all 300ms ease-in-out 0ms;
  }

  footer:after, header:after {
    content: '';
    position: absolute;
    z-index: 2;
    top: calc(50.00% - (1.25em / 2));
    left: 0.25vw;
    width: 1.25em;
    aspect-ratio: 1 / 1;
    border-radius: 100.0%;
    background: url('../../_layout/picts/close_white.svg') pink no-repeat center;
    background-size: 62.00%;
  }

  .header-out header, .footer-out footer {
    display: none;
  }

  /* --- shop-on --- */

  #teaser {
    max-height: 1000.0em;
  }

  .shop-on #teaser {
    max-height: 0;
  }

  #shop {
    max-height: 0;
  }

  .shop-on #shop {
    max-height: 1000.0em;
  }


  /* --- detail-on --- */

  .main {
    flex: 1 1 50.00%;
    max-height: 1000.0em;
  }

  .detail-on .main {
    flex: 0 0 0;
    max-height: 0;
  }

  .detail {
    flex: 0 0 0;
    max-height: 0;
  }

  .detail-on .detail {
    flex: 1 1 50.00%;
    max-height: 1000.0em;
  }

  /* --- menu-on / card-on / search-on --- */

  .menu-on nav > span > button.toggle-menu {
    background-image: url('../img/picts/close.svg');
  }

  .menu {
    flex: 0 0 0;
    max-width: 0
  }

  .menu-on .menu {
    flex: 1 1 100.0%;
    max-width: 25.00em;
    box-shadow: 0 0 0 1000.0em rgba(255, 255, 255, 0.80);
  }

  .card {
    flex: 0 0 0;
    max-width: 0;
  }

  .card-on .card {
    flex: 1 1 100.0%;
    max-width: 25.00em;
    box-shadow: 0 0 0 1000.0em rgba(255, 255, 255, 0.80);
  }

  .card-on .transparentModal-card {
    position: absolute;
    top: -3em;
    height: 1000em;
    width: 1000em;
    z-index: 2;
  }

  .search {
    width: 0;
  }

  .search-on .search {
    width: 25em;
  }

  .menu-on.card-on .menu,
  .menu-on.megasearch-on .menu {
    flex: 0 0 0;
    max-width: 0;
    box-shadow: none;
  }

  .megasearch-on .search {
    height: 100.0%;
  }

  .megasearch-on .search .megasearch {
    max-height: 100.0%;
  }

  .megasearch-on .search .action {
    display: flex;
    opacity: 1.0;
  }

  /* --- card-add-on --- */

  .card-add-00 nav a.cart span,
  .card-add-01 nav a.cart span,
  .card-add-02 nav a.cart span,
  .card-add-03 nav a.cart span,
  .card-add-04 nav a.cart span,
  .card-add-05 nav a.cart span,
  .card-add-06 nav a.cart span,
  .card-add-07 nav a.cart span,
  .card-add-08 nav a.cart span,
  .card-add-09 nav a.cart span,
  .card-add-10 nav a.cart span {
    display: flex;
  }

  .card-add-00 .overlay,
    /*.card-add-01 .overlay,
    .card-add-02 .overlay,
    .card-add-03 .overlay,*/
  .card-add-04 .overlay,
  .card-add-05 .overlay,
  .card-add-06 .overlay,
  .card-add-07 .overlay,
  .card-add-08 .overlay,
  .card-add-09 .overlay,
  .card-add-10 .overlay {
    animation: plop 3s;
    transform: scale(0);
  }

  @keyframes plop {
    0% {
      transform: scale(0);
    }
    5% {
      transform: scale(1);
    }
    95% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }

  .pro-00:before {
    animation: blop infinite ease-in-out 6s;
    transform: scale(1.0);
  }

  @keyframes blop {
    0% {
      transform: scale(1.0);
    }
    47% {
      transform: scale(1.0);
    }
    50% {
      transform: scale(1.4);
    }
    53% {
      transform: scale(1.0);
    }
    100% {
      transform: scale(1.0);
    }
  }

  .claim > span {
    animation: left linear 250ms 1s forwards;
    left: -100.0%;
  }

  @keyframes left {
    0% {
      left: -100.0%;
    }
    100% {
      left: 0;
    }
  }

  .card-add-01 .pro.pro-01 .img:before, .card-add-01 .pro.pro-01 .img:after,
  .card-add-02 .pro.pro-02 .img:before, .card-add-02 .pro.pro-02 .img:after,
  .card-add-03 .pro.pro-03 .img:before, .card-add-03 .pro.pro-03 .img:after {
    animation: plop 3s;
    transform: scale(0);
  }

  /*	=================================
          Form
      ================================= */

  select, input,
  textarea {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  textarea {
    resize: vertical;
  }

  *:focus {
    outline: none;
    outline: -webkit-focus-ring-color auto 0;
  }

  *:-moz-focusring {
    outline: 0;
  }

  .pwm-field-icon {
    display: none;
  }

  ::placeholder {
    opacity: 1.00;
    font-style: italic;
  }

  button {
    background-color: transparent;
  }

  input[type=checkbox],
  input[type=radio] {
    display: none;
  }

  :disabled {
    opacity: 0.75;
  }

  select option {
    font: -moz-pull-down-menu;
  }

  select option:first-child {
    display: none;
  }

  summary::-webkit-details-marker,
  summary::marker {
    display: none;
  }

  /*	=================================
          Style
      ================================= */

  .pro .img:after {
    color: white;
  }

  .control * {
    color: var(--sofa);
  }

  * {
    color: var(--txt-light);
  }

  .head *, .title, .price, .card .item > span + span, .overlay > div {
    color: var(--txt-dark);
  }

  nav, .main, .detail, details[open], .overlay > div, input + label:before {
    background-color: white;
  }

  .btn, input:checked + label:after, .pro .img:before, .searchbox > input + a {
    background-color: var(--sofa);
    color: white;
  }

  nav a.cart span, .cartItem .delete:before {
    transition: all 150ms ease-in-out 0ms;
  }

  .menu, .card, .search {
    background-color: var(--bg-light);
  }

  header, .head *, .title, .price, .card .item > span + span, .overlay > div, .searchbox > input:not(:placeholder-shown) {
    color: var(--txt-dark);
  }

  .pro.top:after {
    background-color: var(--bg-dark);
    color: white;
  }

  *, *:before, *:after {
    border-color: var(--border);
  }

  input:checked + label:before {
    border-color: var(--sofa);
  }

  /*	=================================
          Typo
      ================================= */

  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;

  a, label,
  summary {
    text-decoration: none;
    cursor: pointer;
  }

  small {
    font-size: 0.90em;
  }

  strong {
    font-size: 1.05em;
  }

  header {
    font-size: 1.20em;
  }

  .btn {
    white-space: nowrap;
  }

  p, li {
    line-height: 1.4;
  }

  p, li,
  .title {
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }

  ul {
    font-size: 0.90em;
    list-style: disc;
  }

  li {
    margin-left: 1.00em;
  }

  li + li,
  p + * {
    margin-top: 0.50em;
  }

  p + ul {
    margin-top: 1.00em;
  }

  .pro.top:after,
  a.cart span:after {
    font-size: 0.80em;
  }

  .pro .links > *,
  .pro .img:after,
  footer {
    font-size: 0.90em;
  }

  .btn, .cat .title,
  .head * {
    font-size: 1.15em;
  }

  .tip > .claim > span,
  .descript .block .title,
  .descript .block .price,
  .pro-00:before {
    font-size: 1.50em;
    line-height: 1.4;
  }

  .pro .img:after,
  .overlay > div,
  .card #end {
    text-align: center;
    line-height: 1.4;
  }

  /* --- Fonts --- */

  *, .descript .price span {
    font-family: 'ucl-custom-norm', 'sofo-norm', Courier, monospace;
  }

  .price, .cat .title, .head *, .pro.top:after, a.cart span:after, .control *, .pro .img:after, .searchbox > input:not(:placeholder-shown) {
    font-family: 'ucl-custom-bold', 'sofo-bold', Courier, monospace;
  }

  /* --- Substitution--- */

  .descript details > div b,
  .descript details > div strong {
    font-family: 'ucl-custom-bold', 'sofo-bold', Courier, monospace;
  }

  .descript details > div i {
  }

  .descript details > div font {
    font-family: 'ucl-custom-bold', 'sofo-bold', Courier, monospace;
  }

  .descript details > div h1,
  .descript details > div h2,
  .descript details > div h3,
  .descript details > div h4,
  .descript details > div h5,
  .descript details > div h6 {
    margin: 0 0 0.50em 0;
    font-style: normal;
    font-family: 'ucl-custom-norm', 'sofo-norm', Courier, monospace;
    font-size: 1.00em;
    font-weight: normal;
  }

  .descript details > div a {
  }

  .descript details > div a, p {
    font-size: 1.00em;
    font-family: 'ucl-custom-norm', 'sofo-norm', Courier, monospace;
    font-weight: normal;
    font-style: normal;
  }

  .descript details > div p + p {
    margin-top: 0.50em;
  }

  .descript details > div ol,
  .descript details > div ul {
    font-size: 0.90em;
    font-family: 'ucl-custom-norm', 'sofo-norm', Courier, monospace;
    font-weight: normal;
    font-style: normal;
  }

  .descript details > div ul {
    list-style: disc;
  }

  .descript details > div li {
    margin-left: 1.00em;
    font-size: 1.00em;
  }

  .descript details > div li + li {
    margin-top: 0.50em;
  }

  .descript details > div span,
  .descript details > div div,
  .descript details > div form {
    width: 100.0%;
    margin: 0 0 0.50em 0;
  }

  .descript details > div hr {
    width: 100.0%;
    margin: 0.50em 0;
    border-top-width: 1px;
  }

  .descript details > div sup {
    font-size: 0.80em;
  }

  .descript details > div table {
    width: 100.0%;
    border-collapse: collapse;
    border-spacing: 0;
    border-width: 0;
  }

  .descript details > div tbody {
  }

  .descript details > div tr {
  }

  .descript details > div td {
    border-width: 0;
  }

  .descript details > div u {
    font-size: 1.00em;
    font-family: 'ucl-custom-norm', 'sofo-norm', Courier, monospace;
  }

  .descript details > div o,
  .descript details > div sync4,
  .descript details > div p > crlf,
  .descript details > div p > br {
    display: none;
  }

  /*	=================================
          Style
      ================================= */

  /*	=================================
          END
      ================================= */

}