#itt-sl-ucl, #root, #sl-app {

  .filter-text {
    display: inherit;
  }
  .filter-close-text {
    display: none;
  }
  .menu-on {
    .filter-text {
      display: none;
    }
    .filter-close-text {
      display: inherit;
    }
  }

  nav > span > button {
    position: relative;
    width: 2.50em;
    aspect-ratio: 1 / 1;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1.75em;
  }

  nav > span > button > span {
    padding-left: 2.50em;
    white-space: nowrap;
  }

  nav button.filter {
    background-image: url('../assets/styling/img/picts/tune.svg');
  }

  nav button.cart {
    display: flex;
    align-items: center;
    justify-content: center;

    svg.cart {
      padding: 0;
    }
  }

  nav button.cart span {
    position: absolute;
    top: -0.25em;
    right: -0.50em;
    width: 1.25em;
    aspect-ratio: 1 / 1;
    overflow: hidden;
    border-radius: 100.0%;
    flex-flow: row wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    display: flex;
  }

  nav button.cart span, .cartItem .delete:hover:after {
    background-color: var(--red);
    color: white;
    padding-left: unset;
  }


}