#itt-sl-ucl, #root, #sl-app {

  button.img span {
    opacity: 0;
    color: white;
  }

  .plopOverlay span:nth-child(1) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100.0%;
    height: 100.0%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    transform: scale(0);
    background-color: var(--sofa);
    opacity: 0.5 !important;
    animation: plop 3s;
  }

  .plopOverlay span:nth-child(2) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100.0%;
    height: 100.0%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    transform: scale(0);
    opacity: 1 !important;
    animation: plop 3s;
    font-weight: 600;
    padding: 7px;
  }

  .plopOverlay span {
    animation: plop 3s;
    transform: scale(0);
  }

  .shoppingBag {
    border-radius: 50%;
    padding: 5px;
    margin-left: 0.5em;
  }

  .shoppingBagbefore {
    background-color: var(--bg-ligth);

    path {
      z-index: 10;
      color: #000;
    }
  }

  .shoppingBagafter {
    background-color: var(--sofa);
  }

  .toggle-card-add-01 {
    display: inline;
  }

  .unit-price {
    margin-left: 6px;
    font-size: 0.9em;
  }
}