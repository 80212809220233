﻿#itt-sl-ucl, #root, #sl-app {

#ratio		{	position: absolute; z-index: 999; bottom: -2px; left: -2px; display: flex; flex-flow: row wrap; justify-content: flex-start; align-content: stretch; align-items: stretch; border: 2px solid silver; background-color: white; }
		
#ratio a			{	opacity: 0.50; position: relative; display: inline-block; width: 2.50em; aspect-ratio: 1 / 1; margin: 0; cursor: pointer; background-repeat: no-repeat; background-position: center; background-size: 62.00%; }			
#ratio a:hover		{	opacity: 0.90; }

	#ratio a + a			{	display: block; }			
	#ratio:hover > a + a	{	display: block; }			
	#ratio a:before			{	opacity: 0.00; position: absolute; display: block; top: -1.50em; left: 0; width: 10.00em; font: normal 0.75em/1 Arial, sans; color: black; }			
	#ratio a:hover:before	{	opacity: 1.00; }			
	
		#ratio a:nth-child(01) { background-image: url('../../_layout/picts/devices.svg'); 			}
		#ratio a:nth-child(02) { background-image: url('../../_layout/picts/phone_mac.svg'); 		} 	#ratio  a:nth-child(02):before { content: 'Phone  375 ×  812'; }
		#ratio a:nth-child(03) { background-image: url('../../_layout/picts/phone_mac_rota.svg');	} 	#ratio  a:nth-child(03):before { content: 'Phone  812 ×  375'; }
		#ratio a:nth-child(04) { background-image: url('../../_layout/picts/tablet_mac_rota.svg'); 	} 	#ratio  a:nth-child(04):before { content: 'Pad   1024 ×  768'; }
		#ratio a:nth-child(05) { background-image: url('../../_layout/picts/tablet_mac.svg'); 		} 	#ratio  a:nth-child(05):before { content: 'Pad    768 × 1024'; }
		#ratio a:nth-child(06) { background-image: url('../../_layout/picts/laptop.svg'); 			} 	#ratio  a:nth-child(06):before { content: 'Lap   1366 x  768'; }
		#ratio a:nth-child(07) { background-image: url('../../_layout/picts/laptop.svg'); 			} 	#ratio  a:nth-child(07):before { content: 'Lap   1440 ×  900'; }
		#ratio a:nth-child(08) { background-image: url('../../_layout/picts/desktop_mac.svg'); 		} 	#ratio  a:nth-child(08):before { content: 'PC    1680 x 1050'; }
		#ratio a:nth-child(09) { background-image: url('../../_layout/picts/desktop_mac.svg'); 		} 	#ratio  a:nth-child(09):before { content: 'PC    1920 × 1200'; }
		#ratio a:nth-child(10) { background-image: url('../../_layout/picts/aspect_ratio.svg'); 	} 	#ratio  a:nth-child(10):before { content: 'Fullscreen'; }



}