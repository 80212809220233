﻿#itt-sl-ucl, #root, #sl-app {

	.swiper-container.swiper-list .swiper-slide.grid {
		display: grid;
		gap: 0 5px; /*(!)*/
		grid-template-columns: repeat(auto-fill, minmax(40.00%, 1fr));
	}

	.swiper-button-next,
	.swiper-button-prev {
		position: absolute;
		width: 3.00em;
		height: 3.00em;
		top: calc(50.00% - 1.00em);
		z-index: 3;
		cursor: pointer;
		border-width: 0;
		background-repeat: no-repeat;
		background-size: auto 32.00%;
		background-color: white;
	}

	.swiper-button-next {
		right: -1.50em;
		border-radius: 3.00em 0 0 3.00em;
		background-position: center right 1.50em;
		background-image: url("../img/swiper/swiper-button-next-black.svg");
	}

	.swiper-button-prev {
		left: -1.50em;
		border-radius: 0 3.00em 3.00em 0;
		background-position: center left 1.50em;
		background-image: url("../img/swiper/swiper-button-prev-black.svg");
	}

	/* --- Gallerie --- */

	.swiper-pagination-gal {
		width: 100%;
		margin: 1.00em 0 2.00em;
		display: grid;
		gap: 0.50em;
		grid-template-columns: repeat(auto-fill, minmax(5.00em, 1fr));
	}

	.swiper-pagination-gal .swiper-pagination-bullet {
		display: block;
		width: 100.0%;
		aspect-ratio: 1 / 1;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;

		&:after {
			background-image: url("../../images/play_circle_outline-24px.svg");
			display: block;
			height: 100%;
			width: 100%;
			background-repeat: no-repeat;
			background-position: center;
		}
	}

	.swiper-pagination-gal .swiper-pagination-bullet-active {
		box-shadow: inset 0 0 0 0.25em var(--sofa);
	}

	/* --- Standard ---

    .swiper-pagination 					{	display: block; bottom: 0; width: 100%; }
    .swiper-pagination-bullet 			{	opacity: 0.20; background-color: rgba(000,000,000,0.50); width: 0.75em; height: 0.75em; margin: 0 0.25em; border-radius: 100.0%; }
    .swiper-pagination-bullet-active 	{	opacity: 1.00; background-color: rgba(000,141,232,1.00); }

    */

	.swiper-container {
		/* margin-left: auto; */
		/* margin-right: auto; */
		position: relative;
		overflow: unset !important;
		list-style: none;
		padding: 0;
		z-index: 1;
	}

	.swiper-container-vertical > .swiper-wrapper {
		flex-direction: column;
	}

	.swiper-wrapper {
		position: relative;
		width: 100%;
		height: 100%;
		z-index: 1;
		display: flex;
		transition-property: transform;
		box-sizing: content-box;
	}

	.swiper-container-android .swiper-slide, .swiper-wrapper {
		transform: translate3d(0px, 0, 0);
	}

	.swiper-container-multirow > .swiper-wrapper {
		flex-wrap: wrap;
	}

	.swiper-container-multirow-column > .swiper-wrapper {
		flex-wrap: wrap;
		flex-direction: column;
	}

	.swiper-container-free-mode > .swiper-wrapper {
		transition-timing-function: ease-out;
		margin: 0 auto;
	}

	.swiper-slide {
		flex-shrink: 0;
		width: 100%;
		height: 100%;
		position: relative;
		transition-property: transform;
	}

	.swiper-slide-invisible-blank {
		visibility: hidden;
	}

	.swiper-container-autoheight, .swiper-container-autoheight .swiper-slide {
		height: auto;
	}

	.swiper-container-autoheight .swiper-wrapper {
		align-items: flex-start;
		transition-property: transform, height;
	}

	.swiper-container-3d {
		perspective: 1200px;
	}

	.swiper-container-3d .swiper-cube-shadow, .swiper-container-3d .swiper-slide, .swiper-container-3d .swiper-slide-shadow-bottom, .swiper-container-3d .swiper-slide-shadow-left, .swiper-container-3d .swiper-slide-shadow-right, .swiper-container-3d .swiper-slide-shadow-top, .swiper-container-3d .swiper-wrapper {
		transform-style: preserve-3d;
	}

	.swiper-container-3d .swiper-slide-shadow-bottom, .swiper-container-3d .swiper-slide-shadow-left, .swiper-container-3d .swiper-slide-shadow-right, .swiper-container-3d .swiper-slide-shadow-top {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;
		z-index: 10;
	}

	.swiper-container-3d .swiper-slide-shadow-left {
		background-image: linear-gradient(to left, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
	}

	.swiper-container-3d .swiper-slide-shadow-right {
		background-image: linear-gradient(to right, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
	}

	.swiper-container-3d .swiper-slide-shadow-top {
		background-image: linear-gradient(to top, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
	}

	.swiper-container-3d .swiper-slide-shadow-bottom {
		background-image: linear-gradient(to bottom, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
	}

	.swiper-container-css-mode > .swiper-wrapper {
		overflow: auto;
		scrollbar-width: none;
		-ms-overflow-style: none;
	}

	.swiper-container-css-mode > .swiper-wrapper::-webkit-scrollbar {
		display: none;
	}

	.swiper-container-css-mode > .swiper-wrapper > .swiper-slide {
		scroll-snap-align: start start;
	}

	.swiper-container-horizontal.swiper-container-css-mode > .swiper-wrapper {
		scroll-snap-type: x mandatory;
	}

	.swiper-container-vertical.swiper-container-css-mode > .swiper-wrapper {
		scroll-snap-type: y mandatory;
	}

	/*
    .swiper-button-next, .swiper-button-prev {
        position: absolute;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
        opacity: .35;
        cursor: auto;
        pointer-events: none;
    }
    .swiper-button-prev, .swiper-container-rtl .swiper-button-next {
        right: auto;
    }
    .swiper-button-next, .swiper-container-rtl .swiper-button-prev {
        left: auto;
    }
    */
	.swiper-button-lock {
		display: none;
	}

	.swiper-pagination {
		transition: .3s opacity;
		transform: translate3d(0, 0, 0);
		z-index: 10;
	}

	.swiper-pagination.swiper-pagination-hidden {
		opacity: 0;
	}

	.swiper-container-horizontal > .swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
		left: 0;
		width: 100%;
	}

	.swiper-pagination-bullets-dynamic {
		overflow: hidden;
		font-size: 0;
	}

	.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
		transform: scale(.33);
		position: relative;
	}

	.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active {
		transform: scale(1);
	}

	.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
		transform: scale(1);
	}

	.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
		transform: scale(.66);
	}

	.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
		transform: scale(.33);
	}

	.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
		transform: scale(.66);
	}

	.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
		transform: scale(.33);
	}

	.swiper-pagination-bullet {
		display: inline-block;
	}

	button.swiper-pagination-bullet {
		border: none;
		margin: 0;
		padding: 0;
		box-shadow: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
	}

	.swiper-pagination-clickable .swiper-pagination-bullet {
		cursor: pointer;
	}

	.swiper-container-vertical > .swiper-pagination-bullets {
		right: 10px;
		top: 50%;
		transform: translate3d(0px, -50%, 0);
	}

	.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
		margin: 6px 0;
		display: block;
	}

	.swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
		top: 50%;
		transform: translateY(-50%);
		width: 8px;
	}

	.swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
		display: inline-block;
		transition: .2s transform, .2s top;
	}

	.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
		margin: 0 4px;
	}

	.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
		left: 50%;
		transform: translateX(-50%);
		white-space: nowrap;
	}

	.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
		transition: .2s transform, .2s left;
	}

}
